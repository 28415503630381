<template>
  <div>
    <template v-if="getComponent(widgetDetails.widgetCategoryID)">
      <b-form-row>
        <b-col cols="12">
          <b-form-group>
            <div class="d-flex">
              <div class="floating-input-field">
                <b-form-input
                  id="api-endpoint"
                  :class="{
                    'is-invalid':
                      (!widgetDetails[widgetPropertyKey].apiUrl && formSubmitted) || (!validateUrl(widgetDetails[widgetPropertyKey].apiUrl) && formSubmitted),
                  }"
                  type="url"
                  placeholder=" "
                  v-model="widgetDetails[widgetPropertyKey].apiUrl"
                  required
                ></b-form-input>
                <label for="api-endpoint">API endpoint</label>
              </div>
              <b-button
                type="button"
                id="test-api-btn"
                v-activeBlur
                @click="showTestAPISideBar = true"
                aria-controls="test-api"
                :aria-expanded="showTestAPISideBar"
                variant="outline-secondary"
                class="ml-3"
                >Test API</b-button
              >
            </div>
            <b-form-invalid-feedback class="d-block" v-if="!widgetDetails[widgetPropertyKey].apiUrl && formSubmitted"
              >API endpoint required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              class="d-block"
              v-if="widgetDetails[widgetPropertyKey].apiUrl && formSubmitted && !validateUrl(widgetDetails[widgetPropertyKey].apiUrl)"
              >Invalid url.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-form-row>
      <ApplySecurityMethodForm :formPayload="widgetDetails[widgetPropertyKey]" :formSubmitted="formSubmitted" />
      <TestAPISideBar
        :showTestAPISideBar="showTestAPISideBar"
        :data="widgetDetails[widgetPropertyKey]"
        :widgetDetails="widgetDetails"
        @closeSideBar="showTestAPISideBar = false"
      />
    </template>
  </div>
</template>
<script>
import { useValidateFields } from '@/composables/useValidateFields'
import { WidgetTypesSettings } from '../../../utilities/constants'
export default {
  name: 'API',
  setup() {
    const { validateUrl } = useValidateFields()
    return { validateUrl }
  },
  props: {
    widgetDetails: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTestAPISideBar: false,
      widgetPropertyKey: '',
    }
  },
  components: {
    ApplySecurityMethodForm: () => import('@/components/common/ApplySecurityMethodForm.vue'),
    TestAPISideBar: () => import('./test-api/TestAPISideBar.vue'),
  },
  computed: {
    getComponent() {
      return (id) => {
        const widget = WidgetTypesSettings.find((widget) => widget.widgetCategoryID === id)
        if (widget) {
          // this.widgetDetails[widget.name].applySecurityMethod = !!this.widgetDetails[widget.name].securityMethodId
          this.widgetPropertyKey = widget.name
          return true
        }
        return false
      }
    },
  },
}
</script>
